import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import axios from 'axios'; // Import axios

const firebaseConfig = {
  apiKey: 'AIzaSyC6mvK61qR57JZMSDL12HvNvyz3_b46r20',
  authDomain: 'milav-ctc.firebaseapp.com',
  databaseURL: 'https://milav-ctc-default-rtdb.firebaseio.com',
  projectId: 'milav-ctc',
  storageBucket: 'milav-ctc.appspot.com',
  messagingSenderId: '293032468350',
  appId: '1:293032468350:web:98397bc3585fd8e08f5d2f',
  measurementId: 'G-FL91BXTSX0'
};

const sendTokenToBackend = async (userId, fcmToken) => {
  try {
    const response = await axios.post(
      'https://us-central1-milav-ctc.cloudfunctions.net/saveCallerDetails',
      {
        callerId: userId,
        fcmToken: fcmToken,
        deviceFingerprint: navigator.userAgent // User-Agent string
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer abcdefg' // replace 'abcdefg' with your actual token
        }
      }
    );
    console.log('Token sent to backend successfully', response.data);
  } catch (error) {
    console.error('Failed to send FCM token to backend', error.response?.data || error.message);
  }
};

export const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission !== 'granted') {
    console.log('Notification permission not granted');
  }
};

export const handlePushNotifications = async (userId) => {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      const messaging = getMessaging();
      getToken(messaging, { serviceWorkerRegistration: registration })
        .then((token) => {
          console.log('Token:', token);
          sendTokenToBackend(userId, token);
          // Send the token to your server here
        })
        .catch((error) => {
          console.error('Failed to get token', error);
        });
    })
    .catch((error) => {
      console.error('Service worker registration failed', error);
    });
};

const onForegroundMessage = () => new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
export const receiveForegroundMessage = () => {
  onForegroundMessage()
    .then((payload) => {
      console.log('Received foreground message: ', payload);
      const notificationTitle = payload.notification.title;
      console.log('payload', payload);
      const notificationOptions = {
        body: payload.notification.body,
        icon: '../../public/logo.svg',
        data: { url: payload.fcmOptions.link } // Assuming the URL is sent in click_action
      };
      console.log('notificationOptions', notificationOptions);
      self.registration.showNotification(notificationTitle, notificationOptions);

      self.addEventListener('notificationclick', (event) => {
        const notification = event.notification;
        const clickActionUrl = notification.data.url; // Assuming the URL is in the data property

        event.waitUntil(
          clients
            .openWindow(clickActionUrl, { focus: true, target: '_blank' }) // Open in new tab named "_blank"
            .then((client) => {
              if (client) {
                notification.close(); // Close the notification after successful opening
              }
            })
        );

        // event.waitUntil(
        //   clients
        //     .openWindow(clickActionUrl, { focus: true }) // Open in new tab and focus it
        //     .then((client) => {
        //       if (client) {
        //         notification.close(); // Close the notification after successful opening
        //       }
        //     })
        // );
      });

      // if (Notification.permission === 'granted') {
      //   const notification = new Notification(notificationTitle, notificationOptions);
      //   notification.onclick = function (event) {
      //     event.preventDefault(); // Prevent the browser from focusing the Notification's tab
      //     window.open(notificationOptions.data.url, '_blank');
      //   };
      // }
    })
    .catch((err) => console.log('An error occured while retrieving foreground message. ', err));
};
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const db = getDatabase(app);
