import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import './thankYouPage.scss';
import thankYouImage from './assets/thankYouImg.jpeg';
import Butterfly from './assets/Butterfly.jpg';
import downloadGApp from '../user/assets/downloadApp_img_GApp_Store.png';
import downloadAppleApp from '../user/assets/downloadApp_img_Apple_App_Store.png';

interface Props {}

function ThankYouPage(props: Props) {
  const history = useHistory();
  const {} = props;

  const [osDetails, setOsDetails] = useState(null);
  useEffect(() => {
    //console.log(osDetails)
    generateDeviceFingerprint();
    //if (osDetails) redirectToDownloadApp();
    const handleBack = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };

    window.history.pushState(null, '', window.location.pathname);

    window.addEventListener('popstate', handleBack);

    return () => {
      window.removeEventListener('popstate', handleBack);
    };
  }, [osDetails]);

  function generateDeviceFingerprint(): string {
    let osDetails: any;
    const { screen, navigator } = window;
    const userAgent = window.navigator.userAgent; // Use type assertion
    // Check for Windows
    console.log(userAgent);

    if (userAgent.indexOf('Windows') !== -1) {
      osDetails = 'Windows';
      setOsDetails(osDetails);
      console.log('Operating System: Windows');
    }
    // Check for macOS
    else if (userAgent.indexOf('Macintosh') !== -1) {
      osDetails = 'macOS';
      setOsDetails(osDetails);
      console.log('Operating System: macOS');
    } else if (userAgent.indexOf('iPhone') !== -1) {
      osDetails = 'macOS';
      setOsDetails(osDetails);
    } else if (userAgent.indexOf('Android') !== -1) {
      osDetails = 'android';
      setOsDetails(osDetails);
    } else {
      osDetails = 'unknown';
      setOsDetails(osDetails);
    }

    const fingerprint = `${userAgent} - ${screen.width}x${screen.height} - ${osDetails}`;
    return fingerprint;
  }

  const redirectToDownloadApp = () => {
    generateDeviceFingerprint();
    console.log(osDetails);

    if (osDetails == 'android') {
      window.location.href = 'market://details?id=com.callqr';
      // window.location= "market://details?id=<packagename>"
    } else if (osDetails == 'macOS') {
      window.location.href = 'https://apps.apple.com/us/app/google-maps/id585027354';
    }
  };

  return (
    <div className="container">
      <div className="butterflyImg">
        <img src={Butterfly} alt="Thank You" height={300} width={300} />
        <p style={{ padding: '10px 0px 0px 0px' }}>Feel the butterfly effect of spreading the love.</p>
      </div>
      <div className="containerThankYouPage">
        <div className="directToAppDownloadContainer">
          <h5 style={{ color: 'white' }}>
            Thank you for using <span style={{ fontSize: '18px', color: 'red' }}>CallQR</span>
          </h5>
          <img
            onClick={redirectToDownloadApp}
            src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
            alt="QR"
            height={50}
            width={150}
          />
        </div>
      </div>
    </div>
  );
}

export default ThankYouPage;
