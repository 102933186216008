import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/config.json';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './userDetails.scss';
import homeQR from './assets/hme.png';
import lnfQR from './assets/lnf.png';
import bagQR from './assets/bag.png';
import carQR from './assets/car.png';
import hbdQR from './assets/hbd.png';
import myMusic from './assets/myMusic.mp3';
import downloadGApp from './assets/downloadApp_img_GApp_Store.png';
import downloadAppleApp from './assets/downloadApp_img_Apple_App_Store.png';
import { IoMdCall } from 'react-icons/io';
import { FaUser, FaHome } from 'react-icons/fa';
import { MdBloodtype } from 'react-icons/md';
import SendMessage from '../sendMessage/SendMessage';
import ThankYouPage from '../thankYouPage/ThankYouPage';
import { v4 as uuid } from 'uuid';
import { db } from '../../utils/firebase';
import { onValue, ref, update } from 'firebase/database';
import { getAuth, signInWithCustomToken, User } from 'firebase/auth';
import { handlePushNotifications, requestNotificationPermission, receiveForegroundMessage } from '../../utils/firebase';

interface CallQrUser {
  uid: string;
  session_id: string;
  phone?: string;
  videoMandate?: boolean;
  tag: string;
  messages: Array<string>;
  name: string;
  info: string;
  address: string;
  blood_group: string;
  genericValues: {
    key: string;
  };
}

interface Session {
  acceptedBy: string;
  callStatus: string;
  callerToken: string;
  deviceFingerprint: string;
  timestamp: EpochTimeStamp;
}

const UserDetails = (props: any) => {
  const history = useHistory();
  const { zmClient, setMediaStream, setIsSupportGalleryView, setIsLoading, setIsVideoMandatory } = props;
  //phone number checking code commented
  //const [open, setOpen] = useState(false);
  const [osDetails, setOsDetails] = useState('');
  const [isRejectedOrCancelled, setRejectedOrCancelled] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [realTimeSession, setRealTimeSession] = useState<Session | null>(null);
  const [dataFetch, setDataFetch] = useState(false);
  const [hasJoinedCall, setHasJoinedCall] = useState(false);

  //phone number checking code commented for modal open
  //const onOpenModal = () => setOpen(true);
  //const onCloseModal = () => setOpen(false);

  //const [isOnline, setIsOnline] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const checkInternetConnection = async () => {
    // Check if the browser reports online status
    if (navigator.onLine) {
      try {
        // Attempt to make a network request to verify internet connectivity
        const response = await fetchData();
        console.log(response, 'internet resp');

        if (response?.status == 200) {
          setIsOnline(true);
        } else {
          setIsOnline(false);
        }
      } catch (error) {
        // An error occurred, indicating that the internet is not available
        setIsOnline(false);
      }
    } else {
      // Browser reports that it's offline
      setIsOnline(false);
    }
  };

  const createOrCheckUuid = () => {
    const UniqueCId = localStorage.getItem('cid');
    let unique_id;
    if (UniqueCId) {
      unique_id = UniqueCId;
    } else {
      unique_id = uuid();
      localStorage.setItem('cid', unique_id);
    }
    return unique_id;
  };

  useEffect(() => {
    // Initial check when the component mounts
    //checkInternetConnection();
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    // Event listener to check for changes in online/offline status
    // window.addEventListener('online', checkInternetConnection);
    // window.addEventListener('offline', checkInternetConnection);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      // window.removeEventListener('online', checkInternetConnection);
      // window.removeEventListener('offline', checkInternetConnection);
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
    };
  }, []);

  function generateDeviceFingerprint(): string {
    let osDetails: any;
    const { screen, navigator } = window;
    const userAgent = window.navigator.userAgent; // Use type assertion
    console.log(userAgent);
    console.log(userAgent.indexOf('Windows'));

    if (userAgent.indexOf('Windows') !== -1) {
      osDetails = 'Windows';
      setOsDetails(osDetails);
      console.log('Operating System: Windows');
    }
    // Check for macOS
    else if (userAgent.indexOf('Macintosh') !== -1) {
      osDetails = 'macOS';
      setOsDetails(osDetails);
      console.log('Operating System: macOS');
    } else if (userAgent.indexOf('iPhone') !== -1) {
      osDetails = 'macOS';
      setOsDetails(osDetails);
    } else if (userAgent.indexOf('Android') !== -1) {
      osDetails = 'android';
      setOsDetails(osDetails);
    } else {
      osDetails = 'unknown';
      setOsDetails(osDetails);
    }

    const fingerprint = `${userAgent} - ${screen.width}x${screen.height} - ${osDetails}`;
    return fingerprint;
  }

  const [user, setUser] = useState<CallQrUser | null>(null);

  const [isCameraAvailable, setCameraVisibility] = useState(false);
  const [isAudioInpAvailable, setAudioInpVisibility] = useState(false);
  const [isAudioOutAvailable, setAudioOutVisibility] = useState(false);
  const [showAudioVideoErrorMessage, setShowAudioVideoErrorMessage] = useState(false);
  const [isConnectionInProgress, setConnectionInProgress] = useState(false);
  const [isCancelBtnDisable, setCancelBtnDisable] = useState(false);
  const [callCancellingText, setCallCancellingText] = useState(false);
  const [scanQRLoading, setScanQRLoading] = useState(false);
  const [progress, setProgress] = useState(10);
  const audio = new Audio(myMusic);

  const callInitiateCallAPI = async () => {
    try {
      //setLoadingUserDetails(false);
      listenToRealtimeData();
      const response = await axios.post(`${config.basePath}/callUserV2`, {
        uid: user?.uid,
        sessionId: user?.session_id,
        cid: createOrCheckUuid()
      });
      return response; // Return the response for handling in the component
    } catch (error) {
      console.error('Axios Error:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  const listenToRealtimeData = () => {
    console.log('Listening to firebase realtime data');
    const databaseRef = ref(
      db,
      `sessions/${window.location.pathname.replace(/\//g, '_').slice(1)}/${user?.session_id}`
    );
    return onValue(databaseRef, (snapshot) => {
      if (snapshot.exists()) {
        const sessionValue = snapshot.val();
        setRealTimeSession(sessionValue);
        console.log('Realtime data updated:', sessionValue);
        if (sessionValue.callStatus === 'ringing') {
          setIsRinging(true);
        }
        if (sessionValue.callStatus === 'cancelled' || sessionValue.callStatus === 'rejected') {
          audio.pause();
          setCallCancellingText(false);
          setCancelBtnDisable(false);
          setConnectionInProgress(false);
          setRejectedOrCancelled(true);
          //requestNotificationPermission();
          handlePushNotifications(localStorage.getItem('cid'));
          history.push('/thankyou');
        }
        if (hasJoinedCall) {
          console.log('Call started already, realtime data is late');
        }
        if (!hasJoinedCall && sessionValue.callerToken) {
          if (user?.session_id) {
            setHasJoinedCall(true);
            joinZoomSession(true, user?.session_id, sessionValue.callerToken);
            //setHasJoinedCall(true);
          }
        }
      }
    });
  };

  const joinZoomSession = async (isFromRTDB: boolean, sessionId: string, callerToken: string) => {
    console.log(
      'joining zoom session with sessionId:',
      sessionId,
      'and isFromRTDB:',
      isFromRTDB,
      'and token:',
      callerToken
    );
    if (hasJoinedCall) {
      return;
    }
    try {
      console.log('call initiating');
      await zmClient.join(sessionId, callerToken, 'Guest', '123456');
      const stream = zmClient.getMediaStream();
      console.log('call proceeding', stream);
      setMediaStream(stream);
      console.log('setting mediastream as ', stream);
      setIsSupportGalleryView(stream.isSupportMultipleVideos());
      audio.pause();
      setIsLoading(false);
      const params = {
        videoMandate: user?.videoMandate
      };
      history.push(`/subsession/${user?.videoMandate}`);
    } catch (joinError) {
      console.error('Zoom join error:', joinError);
      history.push('/thankyou');
      setConnectionInProgress(false);
      audio.pause();
    }
  };

  const joinSession = async () => {
    try {
      setConnectionInProgress(true);
      audio.play();
      const response = await callInitiateCallAPI();
      console.log('275', response);
      if (response.status === 200) {
        const { sessionId, callerToken } = response.data;
        // if (!hasJoinedCall) {
        //   joinZoomSession(false, sessionId, callerToken);
        //   setHasJoinedCall(true);
        // } else {
        //   console.log('Call started already, callUser api is late');
        // }
        // if (hasJoinedCall) {
        //   console.log('Call started already, callUser api is late');
        // } else {
        //   setHasJoinedCall(true);
        //   joinZoomSession(false, sessionId, callerToken);
        //   //setHasJoinedCall(true);
        // }
      } else {
        // Handle other cases if needed
        //audio.pause();
        setConnectionInProgress(false);
        setRejectedOrCancelled(true);
        history.push('/thankyou');
      }
    } catch (apiError) {
      audio.pause();
      setConnectionInProgress(false);
      setRejectedOrCancelled(true);
      history.push('/thankyou');
      console.error('API call error:', apiError);
    }
  };

  const checkForCameraAndMic = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    console.log('devices ====>>> ', devices);
    devices.forEach((eachDevices) => {
      if (eachDevices.kind === 'audioinput') {
        setAudioInpVisibility(true);
      }
      if (eachDevices.kind === 'audiooutput') {
        setAudioOutVisibility(true);
      }
      if (eachDevices.kind === 'videoinput') {
        setCameraVisibility(true);
      }
    });
  };

  const fetchData = async () => {
    try {
      //setScanQRLoading(true);
      let currentProgress = progress;
      const interval = setInterval(() => {
        currentProgress += 10;
        if (currentProgress >= 100) {
          clearInterval(interval);
          //setLoading(false);
        }
        setProgress(Math.min(currentProgress, 100));
      }, 1000);
      axios.post(`${config.basePath}/callUserV2`, {
        isDummy: true
      });
      const response = await axios.post(`${config.basePath}/scanQRCodeV2`, {
        // const response = await axios.post(`https://run.mocky.io/v3/13f4b91a-2f77-48b3-a792-3047c56f2938`, {
        qrCodeUrl: window.location.href, // Use the complete URL
        deviceFingerprint: generateDeviceFingerprint(),
        cid: createOrCheckUuid()
      });
      console.log('response status is' + response.status);

      if (response.status === 200) {
        console.log(response.data.data);
        setProgress(100);
        //setScanQRLoading(false);
        setUser(response.data.data);

        const guestToken: string = response.data.data.guestToken;
        console.log('trying to login with guestToken : ' + guestToken);
        const auth = getAuth();
        await signInWithCustomToken(auth, guestToken);
        // Get the Firebase user object
        const user: User | null = auth.currentUser;
        console.log('loggedin user is : ', user?.uid);

        return response;
        // setIsVideoMandatory(response.data.data.videoMandate);
        // setIsVideoMandatory(true);
      }
    } catch (error) {
      //setScanQRLoading(false);
      console.error('Axios Error:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  useEffect(() => {
    fetchData();
    checkForCameraAndMic();
    receiveForegroundMessage();

    return () => {
      console.log('running clean up');
      setAudioInpVisibility(false);
      setAudioOutVisibility(false);
      setCameraVisibility(false);
      setShowAudioVideoErrorMessage(false);
    };
  }, []); // Empty dependency array

  //phone number checking code commented for open caller modal
  // function openCallerOptionModal() {
  //   if (user?.phone) {
  //     onOpenModal();
  //   }
  // }

  function getLocalStream() {
    //onCloseModal();
    //codes are commented for testing over iPhone
    // if (!isCameraAvailable || !isAudioInpAvailable || !isAudioOutAvailable) {
    //   setShowAudioVideoErrorMessage(true);
    //   return;
    // }
    let isVideoMandate = false;
    if (user?.videoMandate === true) {
      isVideoMandate = true;
      console.log(user?.videoMandate);

      // setIsVideoMandatory(true);
    } else {
      isVideoMandate = false;
    }
    navigator.mediaDevices
      .getUserMedia({ video: isVideoMandate, audio: true })
      .then((stream) => {
        joinSession();
      })
      .catch((err) => {
        console.error(`error: ${err}`);
        alert(
          `Please provide ${
            isVideoMandate ? 'camera and microphone' : 'microphone'
          } permission from your browser site settings to proceed further`
        );
      });
    // console.log(user);

    // joinSession();
  }
  const cancelCallAPI = async () => {
    try {
      const response = await axios.post(`${config.basePath}/cancelCallV2`, {
        uid: user?.uid,
        sessionId: user?.session_id
      });
      return response;
    } catch (error) {
      console.error('Axios Error:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  const handleCancelCall = async () => {
    setCallCancellingText(true);
    setCancelBtnDisable(true);
    console.log('handle cancel call');
    try {
      //updating callStatus on cancelling from caller
      const databaseRef = ref(
        db,
        `sessions/${window.location.pathname.replace(/\//g, '_').slice(1)}/${user?.session_id}`
      );
      update(databaseRef, { callStatus: 'cancelled' })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log('Error while updating the records', err);
        });

      const response = await cancelCallAPI();
      console.log(response);
      if (response.status === 200) {
        setCallCancellingText(false);
        setCancelBtnDisable(false);
        setConnectionInProgress(false);
        setRejectedOrCancelled(true);
        history.push('/thankyou');
        //requestNotificationPermission();
        handlePushNotifications(localStorage.getItem('cid'));
        console.log('call Canceled');
      }
      //console.log(response);
    } catch (error) {
      setCallCancellingText(false);
      setCancelBtnDisable(false);
      setRejectedOrCancelled(true);
      //history.push('/thankyou');
      //requestNotificationPermission();
      handlePushNotifications(localStorage.getItem('cid'));
      console.log('cancel call error', error);
    }
  };

  //phone number checking code commented
  // const checkForPhoneNumber = () => {
  //   if (user?.phone) {
  //     openCallerOptionModal();
  //   } else {
  //     getLocalStream();
  //   }
  // };

  const redirectToDownloadApp = () => {
    generateDeviceFingerprint();
    console.log(osDetails);

    if (osDetails == 'android') {
      window.location.href = 'market://details?id=com.callqr';
      // window.location= "market://details?id=<packagename>"
    } else if (osDetails == 'macOS') {
      window.location.href = 'https://apps.apple.com/us/app/google-maps/id585027354';
    }
  };
  return (
    <Fragment>
      <div className="container">
        {/*<Modal open={open} onClose={onCloseModal} center>
          <div className="callOptionModal">
            <a style={{ color: 'black' }} href={`tel:${user?.phone}`}>
              <button className="modalBtn">Call using phone</button>
            </a>
            <button className="modalBtn" onClick={getLocalStream}>
              Call using callQR
            </button>
          </div>
        </Modal>*/}
        {!user ? (
          <div>
            {isOnline ? (
              <div style={{ width: 145, height: 145, padding: '30px' }}>
                <CircularProgressbar
                  value={progress}
                  text={`${progress}%`}
                  strokeWidth={10}
                  styles={buildStyles({
                    textSize: '20px',
                    pathTransitionDuration: 0.5,
                    pathColor: `rgba(62, 152, 199, ${progress / 100})`,
                    textColor: '#f88',
                    trailColor: '#d6d6d6'
                  })}
                />
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '75vh'
                  }}
                >
                  <button onClick={checkInternetConnection} className="callBtn">
                    Retry
                  </button>
                </div>
                <div className="directToAppDownloadContainer">
                  <h5 style={{ color: 'white' }}>
                    Thank you for using <span style={{ fontSize: '24px', color: 'red' }}>CallQR</span>
                  </h5>
                  <img
                    onClick={redirectToDownloadApp}
                    src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
                    alt="QR"
                    height={50}
                    width={150}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <Fragment>
            {isConnectionInProgress ? (
              <div>
                {isOnline ? (
                  <div className="callConnectionInProgress">
                    {isRinging ? (
                      <div>Ringing...</div>
                    ) : callCancellingText ? (
                      <div>Cancelling...</div>
                    ) : (
                      <div>Connecting...</div>
                    )}
                    <button disabled={isCancelBtnDisable} className="cancelCallBtn" onClick={handleCancelCall}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '75vh'
                      }}
                    >
                      <button onClick={checkInternetConnection} className="callBtn">
                        Retry
                      </button>
                    </div>
                    <div className="directToAppDownloadContainer">
                      <h5 style={{ color: 'white' }}>
                        Thank you for using <span style={{ fontSize: '24px', color: 'red' }}>CallQR</span>
                      </h5>
                      <img
                        onClick={redirectToDownloadApp}
                        src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
                        alt="QR"
                        height={50}
                        width={150}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {isOnline ? (
                  <Fragment>
                    {isRejectedOrCancelled ? (
                      <Fragment>
                        <ThankYouPage />
                      </Fragment>
                    ) : (
                      <div>
                        <div className="userDetails">
                          {localStorage.getItem('cid')}
                          <img
                            src={
                              user.tag === 'hme'
                                ? homeQR
                                : user.tag === 'car'
                                ? carQR
                                : user.tag === 'bag'
                                ? bagQR
                                : user.tag === 'lnf'
                                ? lnfQR
                                : hbdQR
                            }
                            alt="QR"
                            height={100}
                            width={100}
                          />
                          <button
                            className="callBtn"
                            // onClick={checkForPhoneNumber}
                            onClick={getLocalStream}
                          >
                            <IoMdCall style={{ marginRight: '10px' }} size={24} />
                            Call
                          </button>
                          {/* {showAudioVideoErrorMessage && (
                        <h4 style={{ color: 'red' }}>
                          Please have a working camera and microphone to join the session
                        </h4>
                      )} */}
                          <div className="userDetailsInfo">
                            {user?.info && (
                              <div className="eachUserDetail">
                                <FaUser style={{ marginRight: '15px' }} size={24} />
                                <h4 style={{ color: 'white' }}>Info :</h4>
                                <h5 style={{ color: 'white' }}>{user.info}</h5>
                              </div>
                            )}
                            {user?.name && (
                              <div className="eachUserDetail">
                                <FaUser style={{ marginRight: '15px' }} size={24} />
                                <h4 style={{ color: 'white' }}>Name :</h4>
                                <h5 style={{ color: 'white' }}>{user.name}</h5>
                              </div>
                            )}
                            {user?.address && (
                              <div className="eachUserDetail">
                                <FaHome style={{ marginRight: '15px' }} size={24} />
                                <h4 style={{ color: 'white' }}>Address :</h4>
                                <h5 style={{ color: 'white' }}>{user.address}</h5>
                              </div>
                            )}
                            {user?.phone && (
                              <div className="eachUserDetail">
                                <IoMdCall style={{ marginRight: '15px' }} size={24} />
                                <h4 style={{ color: 'white' }}>Phone Number :</h4>
                                <h5 style={{ color: 'white' }}>{user.phone}</h5>
                              </div>
                            )}
                            {user?.blood_group && (
                              <div className="eachUserDetail">
                                <MdBloodtype style={{ marginRight: '15px' }} size={24} />
                                <h4 style={{ color: 'white' }}>Blood Group :</h4>
                                <h5 style={{ color: 'white' }}>{user.blood_group}</h5>
                              </div>
                            )}
                            {user?.genericValues && (
                              <div className="genericValues">
                                <ul>
                                  {Object.keys(user.genericValues).map((key: string) => {
                                    return (
                                      <li key={key}>
                                        <h4 style={{ color: 'white', width: 'auto', textAlign: 'left' }}>{key} :</h4>
                                        <h5 style={{ color: 'white' }}>
                                          {user.genericValues[key as keyof typeof user.genericValues]}
                                        </h5>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                          {user?.messages.length > 0 && (
                            <SendMessage
                              messages={user?.messages}
                              senderName={user?.name}
                              userId={user?.uid}
                              cid={localStorage.getItem('cid')}
                              session_id={user?.session_id}
                            />
                          )}
                          {/* <h2>Audio : {isAudioInpAvailable && isAudioOutAvailable ? 'available' : 'audio device not available'}</h2>
              <h2>Video : {isCameraAvailable ? 'available' : 'camera device not available'}</h2> */}
                        </div>
                        <div className="directToAppDownloadContainer">
                          <h5 style={{ color: 'white' }}>
                            Thank you for using <span style={{ fontSize: '24px', color: 'red' }}>CallQR</span>
                          </h5>
                          <img
                            onClick={redirectToDownloadApp}
                            src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
                            alt="QR"
                            height={50}
                            width={150}
                          />
                        </div>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '75vh'
                      }}
                    >
                      <button onClick={checkInternetConnection} className="callBtn">
                        Retry
                      </button>
                    </div>
                    <div className="directToAppDownloadContainer">
                      <h5 style={{ color: 'white' }}>
                        Thank you for using <span style={{ fontSize: '24px', color: 'red' }}>CallQR</span>
                      </h5>
                      <img
                        onClick={redirectToDownloadApp}
                        src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
                        alt="QR"
                        height={50}
                        width={150}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default UserDetails;
